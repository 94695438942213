<template>
  <div class="pre-onboarding">
    <div class="pre-steps">
      <div class="pre-progress">
        <div class="pre-progress--bar pre-progress--bar-active"></div>
        <div class="pre-progress--bar"></div>
        <div class="pre-progress--bar"></div>
        <div class="pre-progress--bar"></div>
      </div>

      <div class="pre-step">
        <div class="pre-subhead centered">Welcome to the</div>

        <div class="pre-headline centered">Paranormal Institute</div>

        <div class="pre-pad"></div>

        <div class="pre-divider"></div>

        <div class="pre-pad"></div>

        <div class="pre-subhead">Creating the Ghostbusters of Tomorrow</div>

        <div class="pre-copy">
          <p>
            Here at the Paranormal Institute, you will receive a full and
            complete education in all things paranormal via Spirit Net, our new
            interactive communications network.
          </p>
          <p>Before you can join, you need to complete an evaluation.</p>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-5">
        <pnw-button variant="primary" size="xl" @click="gotoQuizLanding">
          Start
        </pnw-button>
      </div>
    </div>

    <div class="pre-display">
      <img src="/assets/images/logoGlitch.gif" alt="" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PnwButton from "../../components/PnwButton.vue";
import routerItems from "../../constants/routerItems";
export default {
  components: { PnwButton },
  name: "OnboardingWelcome",
  methods: {
    ...mapActions({
      updateOnboardingData: "registerStore/updateOnboardingData",
    }),
    gotoQuizLanding() {
      this.$router.push({ name: routerItems.QUIZ_LANDING });
    },
  },
  mounted() {
    if (!this.onboardingData.inProgressing) {
      this.$router.replace({ name: routerItems.TICKET_CODE });
    }
  },
  computed: {
    ...mapGetters({
      onboardingData: "registerStore/onboardingData",
    }),
  },
};
</script>
